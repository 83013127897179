<template>
	<v-dialog v-model="showDialog" :max-width="maxWidth" persistent scrollable :width="width" @keydown="onKeydown">
		<v-card>
			<v-toolbar v-if="fullscreen && title" class="white--text" :color="titleColor" dark>
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn v-if="closable" icon @click="onInput(false)">
					<v-icon color="white">close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-title v-else-if="title" :class="`${titleColor} white--text`">
				<v-layout fluid align-center white--text>
					<v-flex headline>{{ title }}</v-flex>
					<v-spacer></v-spacer>
					<v-btn v-if="closable" icon @click="onClose">
						<v-icon color="white">close</v-icon>
					</v-btn>
				</v-layout>
			</v-card-title>
			<v-card-text ref="scrollableElement" :class="{ 'fill-height': fullscreen, 'pa-0': noPadding }" :style="{ 'min-height': minHeight }">
				<!-- @slot Use this slot content -->
				<slot></slot>
			</v-card-text>
			<v-card-actions v-if="$slots.actions">
				<!-- @slot Use this slot actions -->
				<slot name="actions"></slot>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
/**
 * @displayName w-dialog
 */
export default {
	name: 'WDialog',
	props: {
		closable: {
			required: false,
			type: Boolean,
			default: () => true
		},
		error: {
			required: false,
			type: Boolean,
			default: () => false
		},
		fullscreen: {
			default: false,
			required: false,
			type: Boolean
		},
		loading: {
			default: false,
			required: false,
			type: Boolean
		},
		maxWidth: {
			required: false,
			type: String,
			default: () => ''
		},
		minHeight: {
			required: false,
			type: String,
			default: () => ''
		},
		noPadding: {
			required: false,
			type: Boolean,
			default: () => false
		},
		scrollToTop: {
			required: false,
			type: Boolean,
			default: false,
		},
		title: {
			required: false,
			type: String,
			default: () => null
		},
		warning: {
			required: false,
			type: Boolean,
			default: () => false
		},
		width: {
			required: false,
			type: [Number, String],
			default: undefined
		},
	},
	computed: {
		showDialog: {
			get: function () {
				return this.$attrs.value
			},
		},
		titleColor: function () {
			let result = 'secondary'
			if (this.error !== false) {
				result = 'error'
			} else if (this.warning !== false) {
				result = 'warning'
			}
			return result
		}
	},
	watch: {
		scrollToTop(value) {
			if (value) {
				this.$refs.scrollableElement.scroll({
					top: 0,
				});
			}
		}
	},
	mounted () {
		this.$emit('ready')
	},
	methods: {
		onKeydown: function (event) {
			if (event.key === 'Escape' && this.closable) {
				this.onClose(event)
			}
		},
		onClose: function (event) {
			this.$emit('input', false)
			this.$emit('close', event)
		},
	}
}
</script>
